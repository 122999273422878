import { action, observable, computed, autorun, makeObservable } from "mobx";
import User, { UserStore } from "./user.store";
import RecordCheck, { RecordCheckStore } from "./recordCheck.store";
import { LocalStorage } from "utils/localstorage";
import { ProductType } from "types/stores/checkout.types";

export interface ProductDetails {
  id: string;
  name: string;
  type: ProductType;
  price?: number;
  basePrice?: number;
  position?: number;
  priceWithVoucher?: number;
  voucherCode?: string;
}

export interface PriceDetails {
  netAmount: number;
  vatAmount: number;
  vatPercent: number;
}

export interface VoucherCode {
  discountPercent: number;
  voucherCode: string;
  isDiscounted: boolean;
}

const defaultProduct = {
  id: null,
  name: null,
  type: null,
  price: 0,
  basePrice: 0,
  position: 0,
};

const defaultVoucherCode = {
  discountPercent: null,
  voucherCode: null,
  isDiscounted: false,
};

class CheckoutStore {
  private key = "checkout_store_local";
  private localStorage: LocalStorage;

  @observable
  public product: ProductDetails = defaultProduct;

  @observable
  public user: UserStore;

  @observable.deep
  public recordCheck: RecordCheckStore;

  @observable
  public currency: string;

  @observable
  public transactionId?: string;

  public transactionEmail?: string;

  @observable
  public partnerBackUrl: string;

  @observable
  public priceDetails: PriceDetails;

  @observable
  public voucherFromQuery: VoucherCode = defaultVoucherCode;

  constructor() {
    makeObservable(this);
    this.user = User;
    this.recordCheck = RecordCheck;
    this.clearPartnerBackUrl();
    this.localStorage = new LocalStorage(this.key);

    this.loadDataFromLocalStorage();

    autorun(() => {
      this.localStorage.saveDataToLocalStorage(this.toJSON);
    });
  }

  @action
  setCurrency(currency: string) {
    this.currency = currency;
  }

  @action
  setTransaction(transactionId: string, transactionEmail: string) {
    this.transactionId = transactionId;
    this.transactionEmail = transactionEmail;
  }

  @action
  clearTransaction() {
    this.transactionId = undefined;
    this.transactionEmail = undefined;
  }

  @action
  setProduct(product: ProductDetails) {
    this.product = { ...product };
    this.transactionId = undefined;
    this.transactionEmail = undefined;
    this.priceDetails = null;
  }

  @action
  updateProduct(productDataToUpdate: Partial<ProductDetails>) {
    this.product = { ...this.product, ...productDataToUpdate };
  }

  @action
  clearProduct() {
    this.product = defaultProduct;
    this.transactionId = undefined;
    this.transactionEmail = undefined;
    this.priceDetails = null;
  }

  @action
  setPartnerBackUrl(partnerBackUrl: string) {
    this.partnerBackUrl = partnerBackUrl;
  }

  @action
  clearPartnerBackUrl() {
    this.setPartnerBackUrl(null);
  }

  @action
  setPriceDetails(priceDetails: PriceDetails) {
    this.priceDetails = priceDetails;
  }

  @action
  setVoucherFromQuery(voucherFromQuery: VoucherCode) {
    this.voucherFromQuery = voucherFromQuery;
  }

  @computed
  get toObject() {
    return {
      transactionId: this.transactionId,
      transactionEmail: this.transactionEmail,
      currency: this.currency,
      product: {
        price: this.product.price,
        basePrice: this.product.basePrice,
        priceWithVoucher: this.product.priceWithVoucher,
        id: this.product.id,
        type: this.product.type,
        name: this.product.name,
        position: this.product.position,
        voucherCode: this.product.voucherCode,
      },
      partnerBackUrl: this.partnerBackUrl,
    };
  }

  @computed
  get toJSON() {
    return JSON.stringify(this.toObject);
  }

  @computed
  get voucherCodeFromQuery() {
    return this.voucherFromQuery;
  }

  @computed
  get totalPrice() {
    return (this.product.priceWithVoucher ? this.product.priceWithVoucher : this.product.price) || 0;
  }

  @action
  public setDataFromApi(data) {
    const { reportPricing } = data;
    this.clearProduct();
    this.currency = reportPricing.currency;
  }

  @action
  private loadDataFromLocalStorage() {
    const data = this.localStorage.loadDataFromLocalStorage<this>();
    if (!data) {
      return null;
    }

    this.transactionId = data.transactionId;
    this.transactionEmail = data.transactionEmail;
    this.currency = data.currency;
    this.product = data.product || defaultProduct;
    this.partnerBackUrl = data.partnerBackUrl || "";
  }

  neededTransactionRefresh(currentEmail: string) {
    return currentEmail !== this.transactionEmail;
  }

  @computed
  get trackingData() {
    return {
      currency: this.currency,
      price: this.product.price,
      itemName: this.product.id,
      reportDataTotal: this.recordCheck.reportDataTotal,
      vinreg: this.recordCheck.isEncrypted ? this.recordCheck.encryptedQuery : this.recordCheck.query,
      reportCombination: this.recordCheck.reportCombination,
    };
  }
}

export default new CheckoutStore();
export { CheckoutStore };
